import { graphql, Link, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useRef, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import "react-phone-input-2/lib/style.css"
import ErrorIcon from "../assets/images/signup-error.svg"
import Pencil from "../assets/images/signup/pencil-emoji.svg"
import Frame from "../components/common/frame"
import SignupFormTest from "../components/common/sign-up-form-test"
import SVGIcon from "../components/common/SVGIcon"
import Navigation from "../components/navigation"
import CTAThankYou from "../components/utility/cta_thank_you"
import { serverRegions } from "../constants/globalMessages"
import "../styles/pages/signup-2025.scss"

const SignUpTezt = () => {
  const [displayError, setDisplayError] = useState(false)
  const [errorText, setErrorText] = useState("")
  const [serverCountry, setServerCountry] = useState("US")
  const [countryPopup, setCountryPopup] = useState(false)
  const wrapperRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setCountryPopup(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [wrapperRef])

  return (
    <StaticQuery
      query={graphql`
        query contentBetaSignupV2EuTestNew2025 {
          SuperOps {
            pages(where: { title: "Public Beta" }) {
              title
              pageBlock {
                id
                content {
                  html
                }
              }
              navigationBlock {
                name
                slug
                isDropdown
              }
              seo {
                title
                description
                keywords
                image {
                  url
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className="signup dc-signup">
          {data.SuperOps.pages.map(page => {
            const { title, pageBlock, seo } = page

            let MainTitle =
              pageBlock[3].content.html
                .split("<code>")[0]
                .replace(/(<([^>]+)>)/gi, "") + " "
            let underlinedTitle = pageBlock[3].content.html
              .split("<code>")[1]
              .split("</code>")[0]
            return (
              <>
                <Frame seo={seo}>
                  <header>
                    <Navigation
                      page="Home"
                      dskScroll={400}
                      darkHelloBar
                      theme="dark"
                      darkTheme
                      btn1="secondary white"
                      btn2="primary white ml-9"
                    />
                  </header>

                  <section
                    className="betav2-main"
                    style={{
                      backgroundImage: `url("https://media.graphassets.com/MO58K5QNRauWVIw1ef8n")`,
                      backgroundColor: "#000",
                    }}
                  >
                    <Container>
                      <Row>
                        <Col lg={6}>
                          <div className="left">
                            <p className="tag">SIGN UP FOR FREE</p>
                            <div
                              data-sal="slide-up"
                              data-sal-delay="200"
                              data-sal-easing="ease-out-bounce"
                              data-sal-duration="700"
                            >
                              <h1 className="main-title">
                                {MainTitle}

                                <div
                                  className={`position-relative underlined-text word-gradient`}
                                >
                                  <span className="position-relative ">
                                    {underlinedTitle}
                                  </span>
                                </div>
                              </h1>
                            </div>
                            <div
                              data-sal="slide-up"
                              data-sal-delay="300"
                              data-sal-easing="ease-out-bounce"
                              data-sal-duration="700"
                            >
                              <span className="p18">
                                {parse(pageBlock[4].content.html)}
                              </span>
                            </div>
                            <div
                              data-sal="slide-up"
                              data-sal-delay="300"
                              data-sal-easing="ease-out-bounce"
                              data-sal-duration="700"
                            >
                              <span className="p14">
                                {parse(pageBlock[5].content.html)}
                              </span>
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div
                            data-sal="slide-up"
                            data-sal-delay="400"
                            data-sal-easing="ease-out-bounce"
                            data-sal-duration="700"
                          >
                            <div className="right">
                              <section className="cn-cta bt-cta bts-cta">
                                <Container>
                                  {/* <span
                                    className="info"
                                    style={{
                                      display: !displayError ? "block" : "none",
                                    }}
                                  >
                                    <center>
                                      <span className="p16">
                                        {parse(
                                          pageBlock[2].content.html
                                        )}
                                      </span>
                                    </center>
                                  </span> */}
                                  <div
                                    className={`sign-api-error ${
                                      displayError
                                        ? "d-flex align-items-center"
                                        : "dspnone"
                                    }`}
                                  >
                                    <img
                                      src={ErrorIcon}
                                      height="10"
                                      width="10"
                                      className="signup-error-icon"
                                      alt="img"
                                    />
                                    <span className="p14">
                                      <p>{errorText}</p>
                                    </span>
                                  </div>
                                  <SignupFormTest
                                    formAPI={
                                      process.env
                                        .HUBSPOT_BETA_SIGNUP_API_ENDPOINT
                                    }
                                    serverCountry={serverCountry}
                                    setServerCountry={val =>
                                      setServerCountry(val)
                                    }
                                    displayError={displayError}
                                    setDisplayError={setDisplayError}
                                    errorText={errorText}
                                    setErrorText={setErrorText}
                                    disallowCommonDomains
                                    signupEU
                                  />
                                  <span className="action">
                                    <center>
                                      <p className="data-loc mt16">
                                        Your data will be located in the{" "}
                                        <span
                                          ref={wrapperRef}
                                          className="fw-bold text-deep-purple position-relative country-name"
                                          onClick={() =>
                                            setCountryPopup(!countryPopup)
                                          }
                                        >
                                          <div
                                            className={`position-absolute pop-up-location br-10 z-1 ${
                                              countryPopup ? "" : "dspnone"
                                            }`}
                                          >
                                            {Object.values(serverRegions).map(
                                              (item, id) => {
                                                return (
                                                  <p
                                                    className={`text fw-bold p14 ${
                                                      !serverRegions[
                                                        serverCountry
                                                      ] && item.code === "US"
                                                        ? "text-pink"
                                                        : item.code ===
                                                          serverCountry
                                                        ? "text-pink"
                                                        : ""
                                                    }`}
                                                    onClick={() =>
                                                      setServerCountry(
                                                        item.code
                                                      )
                                                    }
                                                  >
                                                    {item.country}
                                                  </p>
                                                )
                                              }
                                            )}
                                            <div className="position-relative">
                                              <SVGIcon
                                                name="polygon"
                                                className="polygon position-absolute"
                                              />
                                            </div>
                                          </div>
                                          {serverRegions[serverCountry]
                                            ?.country ||
                                            serverRegions["US"].country}
                                        </span>
                                      </p>
                                      <p>
                                        By clicking "GET STARTED FOR FREE", you
                                        agree to SuperOps'{" "}
                                        <Link to="/terms">Terms of use</Link>{" "}
                                        and{" "}
                                        <Link to="/privacy">
                                          Privacy policy
                                        </Link>
                                        .
                                      </p>
                                    </center>
                                  </span>
                                  <span className="info-bottom">
                                    <img src={Pencil} alt="note" />
                                    <span className="p14">
                                      {parse(pageBlock[6].content.html)}
                                    </span>
                                  </span>
                                </Container>
                              </section>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </section>
                  <CTAThankYou />
                </Frame>
              </>
            )
          })}
        </div>
      )}
    />
  )
}

export default SignUpTezt
